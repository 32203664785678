export enum AppProviderKeys {
  SearchParameters = '7a76a1e2-1749-4ca5-83cc-626725770d8d',
  Map = '24a782ec-5ac7-4645-b2ef-6a2849b38d33',
  Pagination = '2d47f8df-d8dd-4afd-900c-531229a9f4dd'
}

export enum AppState {
  ClientInfo = '8251dcee-35d6-44ab-b237-b0fe6b1daeab',
  Geolocation = 'ea6d022c-82ab-419b-8b91-46fd161e7748',
  Experiments = 'e4ef3729-186e-4bc8-b808-10110b6be2c9',
  AnonymousId = 'fe76326d-5169-48b8-b68b-16586d0d359c',
  IsBot = '46b8bf84-4516-4e7a-87d2-4e139ce070c8'
}
